"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { scale, breakpoint } from "../../styles";
import { Text, Icon } from "../../components";
import { ThemeContext } from "styled-components";
import { TRANSITION_TIMING } from "../../constants/index";

export interface CircledImagePromoData {
  title?: string;
  preamble?: string;
}

export interface CircledImagePromoProps extends CircledImagePromoData {
  media: React.ReactNode;
}

const CircledImage = styled.figure`
  position: relative;
  width: ${scale.px(24)};
  height: ${scale.px(24)};
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: ${scale.px(3)};
  background-color: ${({ theme }) => theme.primaryColor};

  ${breakpoint("xl")`
		width: ${scale.px(36)};
		height: ${scale.px(36)};
	`}

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    transform: scale(1) translateZ(0);
    backface-visibility: hidden;
    transition: transform 3s cubic-bezier(0.304, 0.132, 0, 0.991);
  }
`;

const Content = styled.div`
  text-align: center;
  padding: 0 ${scale.px(3)};

  p {
    height: ${scale.px(2 * 3)};

    ${breakpoint("m")`
			height: ${scale.px(3 * 3)};
		`}
  }
`;

const CircledImagePromoStyled = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["image"].includes(prop),
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${scale.px(38)};

  svg {
    transform: translateX(0);
    transition: transform 0.3s ${TRANSITION_TIMING};
  }

  &:hover {
    svg {
      transform: translateX(3px);
    }

    ${CircledImage} {
      img {
        transform: scale(1.04) translateZ(0);
      }
    }
  }
`;

const CircledImagePromo: React.FC<CircledImagePromoProps> = (props) => {
  const themeContext = React.useContext(ThemeContext);

  return (
    <CircledImagePromoStyled {...props}>
      <CircledImage>
        {props.media && props.media}
      </CircledImage>

      <Content>
        {props.title && (
          <Text
            as="h3"
            fontSize={{ _: 2.25, xl: 3 }}
            fontWeight="semibold"
            lineHeight={4}
            mb={1}
          >
            {props.title}
          </Text>
        )}
        {props.preamble && (
          <Text
            fontSize={2.25}
            lineHeight={3}
            mb={2}
            truncate={{ _: "twoLine", m: "threeLine" }}
          >
            {props.preamble}
          </Text>
        )}
      </Content>

      {themeContext && (
        <Icon
          icon="arrow-right"
          size={1.5}
          color={themeContext.dark ? "white" : "black-light"}
        />
      )}
    </CircledImagePromoStyled>
  );
};

export { CircledImagePromo };
