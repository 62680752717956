"use client";
import * as React from "react";
import styled, { css } from "styled-components";
import { scale, colors, breakpoint } from "../../styles";
import { Icon } from "../../components";
import { headlineFont } from "@fonts";

export interface CounterLocales {
  incrementBy?: string;
  decrementBy?: string;
}

export interface CounterButtonProps extends CounterLocales {
  ariaControls: string;
}

export interface CounterProps {
  id: string;
  value?: number;
  incrementBy?: number;
  initialValue?: number;
  disabled?: boolean;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onIncrement?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDecrement?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChange?: (value: number) => void;
  locales?: CounterLocales;
  error?: boolean;
}

const counterButtonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 ${scale.px(2)};
  cursor: pointer;

  ${breakpoint("m")`
        padding: 0 ${scale.px(4)};
    `}

  &:disabled {
    opacity: 30%;
    cursor: default;
  }

  &:hover {
    text-decoration: none;
  }
`;

const CounterButtonDecrement = styled.button.attrs<CounterButtonProps>(
  (props) => ({
    "aria-controls": props.ariaControls,
    "aria-label": props.decrementBy,
  })
)<CounterButtonProps>`
  ${counterButtonStyles}
`;

const CounterButtonIncrement = styled.button.attrs<CounterButtonProps>(
  (props) => ({
    "aria-controls": props.ariaControls,
    "aria-label": props.incrementBy,
  })
)<CounterButtonProps>`
  ${counterButtonStyles}
`;

const InputStyled = styled.input`
  flex: 1;
  width: 100%;
  min-width: 0; // Firefox fix
  align-self: stretch;
  font-size: ${scale.px(3)};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  -moz-appearance: textfield;
  border: none;
  padding: ${scale.px(1.5)} 0;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[disabled] {
    opacity: 0.5;
  }
`;

const CounterStyled = styled.div<CounterProps>`
  display: flex;
  width: 100%;
  background-color: ${(props) =>
    props.error ? colors["white"] : colors["brown-normal"]};
  border: ${(props) => (props.error ? `1px solid ${colors["red"]}` : "none")};
  padding: ${scale.px(1)} 0;
  border-radius: 4px;

  ${InputStyled} {
    color: ${(props) => (props.error ? colors["red-dark"] : colors["black"])};
  }
`;

const Counter: React.FC<CounterProps> = ({
  id = "counter",
  initialValue = 0,
  incrementBy = 1,
  disabled = false,
  onChange,
  ...rest
}) => {
  const [internalValue, setInternalValue] = React.useState(initialValue);
  const value = rest.value ?? internalValue;

  const onDecrement =
    rest.onDecrement ||
    function () {
      setInternalValue((prev) => prev - incrementBy);
    };

  const onIncrement =
    rest.onIncrement ||
    function () {
      setInternalValue((prev) => prev + incrementBy);
    };

  const onInputChange =
    rest.onInputChange ||
    function (e: React.ChangeEvent<HTMLInputElement>) {
      setInternalValue(Number(e.target.value));
    };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  React.useEffect(() => {
    onChange && onChange(internalValue);
  }, [onChange, internalValue]);

  return (
    <CounterStyled id={id} error={rest.error}>
      <CounterButtonDecrement
        ariaControls={id}
        onClick={onDecrement}
        disabled={disabled || value === 0}
        {...rest.locales}
      >
        <Icon icon="minus" size={2} />
      </CounterButtonDecrement>
      <InputStyled
        onChange={onInputChange}
        onFocus={onInputFocus}
        value={value}
        type="number"
        disabled={value === 999 || disabled}
        className={headlineFont.className}
      />
      <CounterButtonIncrement
        ariaControls={id}
        onClick={onIncrement}
        disabled={value === 999 || disabled}
        {...rest.locales}
      >
        <Icon icon="plus" size={2} />
      </CounterButtonIncrement>
    </CounterStyled>
  );
};

export { Counter };
