'use client'
import * as React from "react";
import styled from "styled-components";
import { scale, breakpoint } from "../../styles";
import { TRANSITION_TIMING, INNER_MAX_WIDTH } from "../../constants";
import { Text, Flex } from "..";
import { VideoPlayerStyled } from "../VideoPlayer";
import { headlineFont } from "@fonts";

export interface LandingPageHeroData {
  title?: string;
  preamble?: string;
}

export interface LandingPageHeroProps extends LandingPageHeroData {
  media?: React.ReactNode;
}

const Media = styled.div`
  position: relative;
  margin: 0;
  height: 70vh;
  width: 100%;
  overflow: hidden;

  img,
  video,
  ${VideoPlayerStyled} {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    &:after {
      display: none;
    }
  }

  img {
    transform: scale(1.1);
    animation: zoomInImage ${TRANSITION_TIMING} 1s 0.3s forwards;
  }

  ${breakpoint(`l`)`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `}

  @keyframes zoomInImage {
    0% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
`;

const Content = styled.div`
  position: relative;
  padding: ${scale.px(5)};
  margin: ${scale.px(-9)} 0 ${scale.px(2)};
  background-color: ${({ theme }) => theme.primaryColor};
  text-align: center;
  width: 100%;

  p {
    margin-bottom: 0;
  }

  ${breakpoint(`m`)`
		margin: ${scale.px(-9)} 0 ${scale.px(5)};
	`}

  ${breakpoint(`xl`)`
		padding: ${scale.px(8)};
		padding-bottom: ${scale.px(9)};
		margin: ${scale.px(-23)} 0 ${scale.px(8)};
		text-align: left;
		max-width: ${scale.px(108)};
	`}

	${breakpoint(`xxl`)`
		max-width: 66.6%;
	`}
`;

const Inner = styled.div`
  position: relative;

  ${breakpoint(`l`)`
		height: 90vh;

		&:after {
			content: '';
			display: block;
			padding-bottom: 46.66%;
		}
	`}
`;

const LandingPageHeroStyled = styled.div`
  position: relative;
`;

const LandingPageHero: React.FC<
  React.PropsWithChildren<LandingPageHeroProps>
> = (props) => {
  return (
    <LandingPageHeroStyled>
      <Inner>{props.media && <Media>{props.media}</Media>}</Inner>
      <Flex
        style={{ margin: "0 auto" }}
        px={{ _: 2, m: 5, l: 10 }}
        justifyContent={"center"}
      >
        <Flex maxw={INNER_MAX_WIDTH} w={1}>
          <Content className={headlineFont.className}>
            {props.title && (
              <Text variant="headline-1" as="h1" mb={{ _: 3, l: 5 }}>
                {props.title}
              </Text>
            )}
            {props.preamble && (
              <Text
                variant="preamble"
                fontSize={{ _: 2.5, xxl: 3 }}
                lineHeight={{ _: 3.5, xxl: 4 }}
                mb={1}
              >
                {props.preamble}
              </Text>
            )}
            {props.children}
          </Content>
        </Flex>
      </Flex>
    </LandingPageHeroStyled>
  );
};

export { LandingPageHero };
