"use client";
import * as React from "react";
import styled from "styled-components";
import { Flex } from "../../components";
import { colors } from "../../styles";
import { logoBlackBase64, logoWhiteBase64 } from "./assets";
import { ThemeContext } from "styled-components";
import { TextLogo } from "./Logo";
import Image from "next/image";

const HeaderLogoStyled = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const HeaderLogo: React.FC = (props) => {
  const themeContext = React.useContext(ThemeContext);

  if (themeContext) {
    return (
      <Flex
        justifyContent={{ _: "flex-start", xxs: "center" }}
        alignItems="center"
      >
        <Flex
          style={{ position: "relative" }}
          w={{ _: 5, xl: 6 }}
          h={{ _: 5, xl: 6 }}
          mr={{ _: 1, xl: 2 }}
        >
          <HeaderLogoStyled 
            src={themeContext.dark ? 
              "/img/GOlogo-header-white.png" : 
              "/img/GOlogo-header-black.png"} 
            alt={""} 
            width={100}
            height={100}
          />
        </Flex>
        <Flex
          style={{ position: "relative" }}
          pt={1}
          w={{ _: 0, xxs: 8, m: 10, xl: 12 }}
          h={{ _: 0, xxs: 4.5, m: 5, xl: 5 }}
        >
          {themeContext.dark ? (
            <TextLogo fill={colors["white"]} />
          ) : (
            <TextLogo fill={colors["black"]} />
          )}
        </Flex>
      </Flex>
    );
  }
};
