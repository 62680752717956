"use client";
import React from "react";
import { Text } from "../Text";
import styled, { WebTarget } from "styled-components";
import { scale, breakpoint, colors } from "../../styles";
import noForwardProps from "../../../../../utils/restrictPropsToDOM";
import { Flex } from "../Flex";
import { headlineFont } from "@fonts";

export interface CardData {
  tag?: string;
  name?: string;
  meta?: string;
  title?: string;
  editorsPick?: string;
}

export interface CardProps extends CardData {
  media?: React.ReactNode;
  bgColor?: string;
  bgGradient?: boolean;
}

const transition = "cubic-bezier(.789, .301, 0, .956) 0.38s";

const Content = styled.div`
  transform: translateY(0);
  padding-top: calc(${(9 / 16) * 100}% + ${scale.px(4)});
  display: block;
`;

const CardPick = styled.div`
  position: absolute;
  left: 0;
  transform: translateY(0);
  margin-top: ${scale.px(2)};
  display: block;
  background-color: ${colors["cyan-blue"]};
  color: ${colors["black-light"]};
  min-width: ${scale.px(16)};
  text-align: center;
  font-size: ${scale.px(2)};
  line-height: ${scale.px(2)};
`;

const MediaBackground = styled.div.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !noForwardProps.includes(prop),
})<Pick<CardProps, "bgGradient">>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${(props) =>
    props.bgGradient
      ? `
				linear-gradient(
					305.42deg,
					${props.theme.primaryColor} 1.59%,
					${props.theme.complementaryColor} 99.33%
				)
			`
      : `${props.theme.primaryColor}`};
  transition:
    transform ${transition},
    box-shadow ${transition};
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0);
`;

const Media = styled.figure`
  position: relative;
  display: block;
  height: auto;
  width: 100%;
  padding-bottom: ${(9 / 16) * 100}%;
  transform-origin: center 100%;
  transform: scale(0.9);
  transform-origin: center 85%;
  margin: 0;

  > * {
    position: absolute;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
`;

const CardStyled = styled.div`
  padding: ${scale.px(2)} ${scale.px(2)} ${scale.px(5)};
  position: relative;
  width: 100%;
  height: 100%;

  ${Media}, ${Content}, ${CardPick} {
    transition: transform ${transition};
  }

  ${breakpoint("l")`
		&:hover  {
			z-index: 2;
			${MediaBackground}  {
				transform: scale(1.05);
				box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.16);
			}
			${Media}  {
				transform: scale(0.9);
			}
			${CardPick}  {
				transform: translateX(-${scale.px(1.2)});
			}
			${Content}  {
				transform: translateY(2%);
				h3  {
					text-decoration: underline;
				}
			}
		}
	`}

  ${breakpoint("xxl")`
		padding: ${scale.px(2)} ${scale.px(3)} ${scale.px(5)};
	`}
`;

export const Card: React.FC<CardProps> = ({
  bgGradient = true,
  media,
  tag,
  name,
  meta,
  title,
  editorsPick,
}) => (
  <CardStyled>
    <MediaBackground bgGradient={bgGradient}>
      {media && <Media>{media}</Media>}
    </MediaBackground>
    {editorsPick && (
      <CardPick>
        <Flex display="block" m={1} pl={2} pr={1} color={colors["black-light"]}>
          {editorsPick.toUpperCase()}
        </Flex>
      </CardPick>
    )}
    <Content>
      {tag && (
        <Text
          as="span"
          display="block"
          textAlign="center"
          variant="tag"
          mb={0.5}
          fontSize={1.5}
          lineHeight={2.25}
        >
          {tag}
        </Text>
      )}
      {name && (
        <Text
          textAlign="center"
          variant="headline-1"
          as="h2"
          mb={3}
          fontSize={{
            _: 2.25,
            m: 2.5,
            xxl: 3,
          }}
          lineHeight={{
            _: 3.25,
            m: 3.5,
            xxl: 4,
          }}
          wordBreak="break-word"
        >
          {name}
        </Text>
      )}
      {meta && (
        <Text
          textAlign="center"
          fontSize={{ _: 1.75, m: 2 }}
          lineHeight={{ _: 2.25, m: 3 }}
          wordBreak="break-word"
        >
          {meta}
        </Text>
      )}
      {title && (
        <Text
          textAlign="center"
          variant="headline-3"
          fontSize={{
            _: 3,
            xxl: 4,
          }}
          lineHeight={{
            _: 4,
            xxl: 5,
          }}
          // This text is redundant for screen readers, so we hide it from them
          aria-hidden="true"
          wordBreak="break-word"
        >
          {title}
        </Text>
      )}
    </Content>
  </CardStyled>
);
