import styled from "styled-components";

export const Svg = styled.svg`
  display: block;
  width: 100%;
`;

export const TextLogo = ({ fill = "" }) => (
  <Svg viewBox="0 0 600 200" fill={fill}>
    <path className="b" d="M31.34019,154.57766c-22.10069,0-31.34019-8.6633-31.34019-30.23276v-4.42185c0-21.56945,9.2395-30.3233,31.34019-30.3233h1.53922c22.01084,0,31.34019,8.75385,31.34019,30.3233v4.42185c0,21.56946-9.32934,30.23276-31.34019,30.23276h-1.53922ZM46.19471,123.71321v-3.24899c0-10.28745-1.99263-16.42468-13.40514-16.42468h-1.35883c-11.50375,0-13.49568,6.13723-13.49568,16.42468v3.24899c0,10.19761,1.99193,16.42539,13.49568,16.42539h1.35883c11.41251-.09054,13.40514-6.22777,13.40514-16.42539" />
    <path className="b" d="M70.34253,153.67497v-63.17268h35.66868c17.39111,0,23.45953,6.22708,23.45953,21.29854v2.1653c0,14.62085-7.06509,21.2087-23.45953,21.2087h-18.00666v18.50014h-17.66203ZM88.00455,104.03946v17.59822h15.38023c6.70293,0,8.33268-2.25584,8.33268-8.57345v-.6317c0-6.498-1.99192-8.39307-8.42322-8.39307h-15.28969Z" />
    <polygon className="b" points="134.92236 153.6754 134.92236 90.50202 186.97213 90.50202 186.97213 104.3101 152.58439 104.3101 152.58439 115.1401 186.97213 115.1401 186.97213 128.94747 152.58439 128.94747 152.58439 139.86732 186.97213 139.86732 186.97213 153.6754 134.92236 153.6754" />
    <path className="b" d="M193.78729,153.67518v-63.17337h34.04377c18.38777,0,24.36565,4.332,24.36565,17.14762v1.17283c0,8.93493-2.80751,13.80808-10.41585,14.98162v.6317c7.33672,1.26339,10.41585,3.15776,10.41585,14.70929v14.53031h-17.75257v-13.98846c0-5.95545-1.90208-7.49046-9.51113-7.49046h-13.48376v21.47892h-17.66197ZM211.44926,104.12951v14.52961h15.74802c5.07246,0,7.24687-1.71399,7.24687-6.76823v-.36076c0-5.77647-2.08387-7.40062-8.96718-7.40062h-14.02771Z" />
    <path className="b" d="M381.32572,79.21815V16.04478h34.04377c18.38777,0,24.36565,4.332,24.36565,17.14762v1.17283c0,8.93493-2.80751,13.80808-10.41585,14.98162v.6317c7.33672,1.26339,10.41585,3.15776,10.41585,14.70929v14.53031h-17.75257v-13.98846c0-5.95545-1.90208-7.49046-9.51113-7.49046h-13.48376v21.47892h-17.66197ZM398.98769,29.67247v14.52961h15.74802c5.07246,0,7.24687-1.71399,7.24687-6.76823v-.36076c0-5.77647-2.08387-7.40062-8.96718-7.40062h-14.02771Z" />
    <path className="b" d="M309.40732,153.67476l-4.34811-12.72437h-25.27037l-4.34813,12.72437h-18.93033l23.00613-63.17267h26.6299l23.00613,63.17267h-19.74521ZM284.31735,127.23285h16.21334l-7.78942-22.83214h-.63449l-7.78942,22.83214Z" />
    <polygon className="b" points="371.95011 153.6754 351.08893 109.99532 350.47057 109.99532 350.47057 153.6754 333.76385 153.6754 333.76385 90.50202 359.84063 90.50202 380.79022 134.18139 381.32015 134.18139 381.32015 90.50202 398.02695 90.50202 398.02695 153.6754 371.95011 153.6754" />
    <path className="b" d="M31.56865,80.12097C9.54798,80.12097.34287,71.45764.34287,49.88748v-4.42112C.34287,23.89684,9.54798,15.14303,31.56865,15.14303h1.5336c17.77923,0,27.88696,5.68521,30.41371,19.9439h-18.5913c-1.44376-4.06036-5.41428-5.50482-11.91295-5.50482h-1.35322c-11.46094,0-13.44655,6.13721-13.44655,16.42539v3.60976c0,10.28881,1.98561,16.42539,13.44655,16.42539h1.98561c9.92664-.09124,12.54393-2.79768,12.54393-11.28134h-15.97401v-13.44662h33.66269v8.66404c0,21.47891-8.84434,30.14224-30.77447,30.14224h-1.5336Z" />
    <polygon className="b" points="153.00102 79.21843 153.00102 31.1165 135.39443 31.1165 135.39443 16.04502 188.7476 16.04502 188.7476 31.1165 170.77956 31.1165 170.77956 79.21843 153.00102 79.21843" />
    <polygon className="b" points="193.78688 79.21843 193.78688 16.04502 244.93959 16.04502 244.93959 29.85311 211.38495 29.85311 211.38495 40.68307 244.93959 40.68307 244.93959 54.49046 211.38495 54.49046 211.38495 65.41033 244.93959 65.41033 244.93959 79.21843 193.78688 79.21843" />
    <path className="b" d="M286.83147,79.21836h-35.28688V16.04495h34.29442c17.05631,0,22.38074,5.59536,22.38074,16.06463v.81207c0,7.76135-2.97878,12.63455-10.91981,13.62701v.63168c8.66399.81207,11.73118,5.59536,11.73118,13.62764v1.26344c0,11.46166-5.77504,17.14693-22.19966,17.14693M269.14275,29.67196v11.82312h14.34988c5.41359,0,6.94789-.99245,6.94789-5.50482v-.36147c0-4.51237-1.53431-5.95683-7.85049-5.95683h-13.44728ZM269.14275,53.04663v12.54401h13.35674c7.03912,0,8.84434-1.26338,8.84434-6.04674v-.6317c0-4.96297-1.71468-5.86558-9.02544-5.86558h-13.17563Z" />
    <path className="b" d="M343.85727,80.12062c-22.02062,0-31.22572-8.66334-31.22572-30.23279v-4.42183c0-21.56952,9.20511-30.32333,31.22572-30.32333h1.5336c21.93016,0,31.22579,8.75381,31.22579,30.32333v4.42183c0,21.56945-9.29563,30.23279-31.22579,30.23279h-1.5336ZM358.65776,49.25615v-3.24899c0-10.28748-1.98491-16.4247-13.35742-16.4247h-1.35252c-11.46158,0-13.44719,6.13721-13.44719,16.4247v3.24899c0,10.19756,1.98562,16.42539,13.44719,16.42539h1.35252c11.37251-.09055,13.35742-6.22783,13.35742-16.42539" />
    <path className="b" d="M474.67954,80.12097c-22.02066,0-31.22581-8.66333-31.22581-30.23349v-4.42112c0-21.56952,9.20515-30.32333,31.22581-30.32333h1.53356c17.77924,0,27.88697,5.68521,30.41371,19.9439h-18.5913c-1.44377-4.06036-5.41431-5.50482-11.91297-5.50482h-1.35316c-11.46093,0-13.44653,6.13721-13.44653,16.42539v3.60976c0,10.28881,1.9856,16.42539,13.44653,16.42539h1.98555c9.92733-.09124,12.54395-2.79768,12.54395-11.28134h-15.97395v-13.44662h33.66263v8.66404c0,21.47891-8.84432,30.14224-30.77446,30.14224h-1.53356Z" />
    <path className="b" d="M540.10324,80.12097c-17.50832,0-28.51795-5.59543-29.87188-19.13253h18.68113c1.26341,5.05422,6.13654,5.86629,11.82245,5.86629,7.21947,0,10.559-1.80523,10.559-6.04674v-.90191c0-3.61045-1.9856-5.14476-8.57413-5.41498l-8.66324-.27162c-15.52273-.54045-22.65237-5.77505-22.65237-17.959v-.4513c0-13.62701,8.75448-20.66614,27.25526-20.66614h.90189c17.14758,0,27.97684,7.12967,29.15038,19.9439h-18.68117c-1.26406-5.05351-5.14473-6.67836-11.10087-6.67836-6.76889,0-9.56659,1.80523-9.56659,6.04737v.36077c0,3.79013,1.89576,5.32444,8.48357,5.50482l8.66396.36147c15.52204.54114,22.65168,5.77581,22.65168,17.95899v.63168c0,14.89109-10.28808,20.84729-28.24772,20.84729h-.81137Z" />
    <path className="b" d="M100.52279,80.196c-22.02069,0-31.22576-8.66334-31.22576-30.23278v-4.42183c0-21.56952,9.20508-30.32333,31.22576-30.32333h1.5336c21.93015,0,31.2258,8.75381,31.2258,30.32333v4.42183c0,21.56944-9.29565,30.23278-31.2258,30.23278h-1.5336ZM115.32328,49.33153v-3.24899c0-10.28748-1.98491-16.42469-13.35742-16.42469h-1.35252c-11.46165,0-13.44726,6.1372-13.44726,16.42469v3.24899c0,10.19757,1.98561,16.4254,13.44726,16.4254h1.35252c11.37251-.09055,13.35742-6.22782,13.35742-16.4254" />
    <rect className="b" x="85.37667" width="13.49919" height="10.88124" />
    <rect className="b" x="103.70337" width="13.49919" height="10.88124" />
  </Svg>
);
