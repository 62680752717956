export type ColorKeys =
  | "black"
  | "black-light"
  | "white"
  | "white-faded"
  | "off-white"
  | "blue"
  | "blue-dark"
  | "blue-light"
  | "blue-lighter"
  | "brown-normal"
  | "brown"
  | "brown-light"
  | "grey"
  | "grey-light"
  | "grey-dark"
  | "greybrown"
  | "greybrown-dark"
  | "greybrown-medium"
  | "greybrown-light"
  | "pink-light"
  | "red"
  | "red-dark"
  | "gold"
  | "gold-light"
  | "green"
  | "green-light"
  | "cyan-blue"
  | "light-grey"
  | "transparent";

export const colors: Record<ColorKeys, string> = {
  black: "#0f0f0f",
  "black-light": "#222222",
  white: "#FFFFFF",
  "white-faded": "#FFFFFFA6",
  "off-white": "#FFFEFC",
  blue: "#215160",
  "blue-dark": "#072933",
  "blue-light": "#E1F2F0",
  "blue-lighter": "#F6FBFA",
  "brown-normal": "#EDECE9",
  brown: "#735B34",
  "brown-light": "#F8F7F6",
  gold: "#D8BF72",
  "gold-light": "#FFF0BB",
  grey: "#646768",
  "grey-light": "#EFEFEF",
  "grey-dark": "#494949",
  greybrown: "#ECEBE5",
  "greybrown-dark": "#6B6761",
  "greybrown-medium": "#CDCBC7",
  "greybrown-light": "#FFFEFC",
  "pink-light": "#FAE3DE",
  red: "#FF7373",
  "red-dark": "#F13D3D",
  green: "#4AA253",
  "green-light": "#DDF2DA",
  "cyan-blue": "#32D9FA",
  "light-grey": "#f2f1eb",
  transparent: "transparent",
};

export const colorKeys = Object.keys(colors) as ColorKeys[];
