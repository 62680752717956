'use client'
import styled from "styled-components";
import { scale, colors, breakpoint } from "../../styles";
import { Text } from "../../components";
import { headlineFont } from "@fonts";

export interface ChipProps {
  onClick?: () => any;
  className?: string;
  disabled?: boolean;
}

const ChipStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  background-color: ${({ theme }) =>
    theme.dark ? "transparent" : colors["brown-light"]};
  border: 2px solid;
  border-color: ${({ theme }) =>
    theme.dark ? colors["white"] : `rgba(0, 0, 0, 0.05)`};
  border-radius: ${scale.px(5)};
  padding: ${scale.px(1)} ${scale.px(3)};
  padding-top: 9px;
  transition: all 0.15s ease-in-out;

  ${breakpoint("l")`
		padding: ${scale.px(1.5)} ${scale.px(3)};
	`}

  & > * {
    color: ${({ theme }) => (theme.dark ? colors["white"] : colors["black"])};
  }

  &:hover,
  &.active {
    background-color: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["black"]};

    & > * {
      color: ${({ theme }) => (theme.dark ? colors["black"] : colors["white"])};
    }
  }
`;

const Chip: React.FC<React.PropsWithChildren<ChipProps>> = ({
  children,
  className = "",
  disabled = false,
  ...rest
}) => (
  <ChipStyled className={className} disabled={disabled} {...rest}>
    <Text
      fontSize={{ _: 1.5, m: 1.75 }}
      lineHeight={{ _: 1.5, m: 1.75 }}
      textTransform="uppercase"
      mb={0}
      style={headlineFont.style}
    >
      {children}
    </Text>
  </ChipStyled>
);

export { Chip };
