"use client";
import * as React from "react";
import styled, { WebTarget } from "styled-components";
import { scale, colors, ColorKeys } from "../../styles";
import { Icon } from "../Icon";
import { IconTypes } from "../Icon/types";

export interface IconLinkLocales {
  label: string;
}

export interface IconLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  icon: IconTypes;
  locales: IconLinkLocales;
  color?: ColorKeys;
}

const IconLinkStyled = styled.a.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["icon"].includes(prop),
})`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 50%;
  text-decoration: none;

  svg {
    margin-bottom: ${scale.px(2)};
    opacity: 1;
    transition: opacity 0.05s linear;
  }

  span {
    color: ${(props) => props.color ?? colors.white};
    font-size: ${scale.px(1.5)};
    text-decoration: none;
  }

  &:hover {
    svg {
      opacity: 0.7;
    }

    span {
      text-decoration: underline;
    }
  }
` as React.FC<IconLinkProps>;

const IconLink: React.FC<IconLinkProps> = ({ locales, size, color, ...rest }) => {
  color = color ?? "white";
  return (
    <IconLinkStyled {...(rest as IconLinkProps)}>
      <Icon icon={rest.icon} size={size} color={color} />
      <span style={{color: color}}>{locales.label}</span>
    </IconLinkStyled>
  );
};

export { IconLink };
