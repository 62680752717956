"use client";
import * as React from "react";
import Link, { LinkProps } from "next/link";
import styled, { WebTarget } from "styled-components";
import { scale, colors } from "../../styles";
import { fontWeight } from "../../styles/constants/typography";
import { SpaceProps } from "../../styles/styled-system";

export interface SubmenuListItemStyledProps extends SpaceProps {
  active?: boolean;
}

export interface SubmenuLinkStyledProps extends LinkProps {
  underline?: boolean;
  nolink?: boolean;
}

export interface SubmenuProps {
  List: React.FC<React.PropsWithChildren>;
  Item: React.FC<React.PropsWithChildren<SubmenuListItemStyledProps>>;
  Link: React.FC<React.PropsWithChildren<SubmenuLinkStyledProps>>;
}

const ListStyled = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const LinkStyled = styled(Link).withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["underline", "nolink"].includes(prop),
})<SubmenuLinkStyledProps>`
  font-size: ${scale.px(2)};
  font-weight: ${fontWeight.normal};
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};

  &:hover {
    text-decoration: ${(props) => (props.nolink ? "none" : "underline")};
  }
`;
export const ListItemStyled = styled.li.withConfig({
  shouldForwardProp: (prop: any, elementToBeCreated: WebTarget) =>
    !["active"].includes(prop),
})<SubmenuListItemStyledProps>`
  position: relative;
  margin-bottom: ${scale.px(1)};
  font-size: ${scale.px(2.25)};
  line-height: ${scale.px(3)};

  &:before {
    content: "";
    display: ${(props) => (props.active ? "block" : "none")};
    position: absolute;
    top: 0;
    left: ${scale.px(-2)};
    height: 100%;
    width: ${scale.px(0.5)};
    background-color: ${colors["cyan-blue"]};
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${LinkStyled} {
    color: ${({ theme }) =>
      theme.dark ? colors["white"] : colors["black-light"]};
  }
`;

const Submenu: React.FC<React.PropsWithChildren> & SubmenuProps = (props) => (
  <nav {...props} />
);

Submenu.List = ListStyled;

const Item = (props: SubmenuListItemStyledProps) => (
  <ListItemStyled {...props} />
);

Submenu.Item = Item;

Submenu.Link = (props: SubmenuLinkStyledProps) => (<LinkStyled {...props} />);

export { Submenu };
