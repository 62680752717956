"use client";
import styled from "styled-components";
import { Flex } from "../../components";
import { colors, scale, lightTheme } from "../../styles";
import { useInView } from "react-intersection-observer";
import { ClientThemeProvider as ThemeProvider } from "../../../../ClientThemeProvider";

export interface FooterProps {
  leftContent: any;
  rightContent: any;
  bottomContent: any;
  languageSwitch?: React.ReactNode;
  logo?: React.ReactNode;
}

const FooterStyled = styled.footer`
  background-color: ${colors["cyan-blue"]};
  a {
    color: ${colors["black"]};
    text-decoration-color: ${colors["black"]};
  }
`;

const FooterLogoStyled = styled.div`
  position: relative;
  max-width: ${scale.px(30)};
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export const FooterLinkStyled = styled.a`
  text-decoration: underline;
  text-decoration-color: ${colors["black"]};
`;

const Footer: React.FC<FooterProps> = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <ThemeProvider theme={lightTheme}>
      <FooterStyled ref={ref}>
        <Flex
          maxw={180}
          w={1}
          style={{ margin: "0 auto" }}
          px={{ _: 2, l: 8 }}
          pt={{ _: 9, l: 20 }}
          flexDirection="column"
        >
          <Flex flexDirection={{ _: "column", l: "row" }} pb={{ _: 0, l: 5 }}>
            <Flex flex={4 / 12}>
              <Flex flexDirection="column" mb={{ _: 6, l: 0 }} w={1}>
                {props.leftContent}
              </Flex>
            </Flex>
            <Flex flex={1 / 12}></Flex>
            <Flex flex={6 / 12}>
              <Flex flexDirection="column" w={1}>
                {props.rightContent}
              </Flex>
            </Flex>
          </Flex>
          {props.logo && inView && (
            <Flex justifyContent="center" mb={{ _: 3, l: 8 }}>
              <FooterLogoStyled>{props.logo}</FooterLogoStyled>
            </Flex>
          )}
          {props.languageSwitch && props.languageSwitch}
          <Flex
            style={{ borderTop: `1px solid ${colors["blue"]}` }}
            pt={4}
            px={{ _: 2, l: 0 }}
            pb={{ _: 2, l: 2 }}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Flex mb={3} flexDirection="column" alignItems="center">
              {inView && props.bottomContent}
            </Flex>
          </Flex>
        </Flex>
      </FooterStyled>
    </ThemeProvider>
  );
};

export { Footer };
