import localFont from "next/font/local";

const neutra2Text = localFont({
  src: [
    {
      path: "../../public/fonts/Neutra2Text-Light.otf",
      weight: "200",
      style: "normal",
    },
    {
      path: "../../public/fonts/Neutra2Text-LightItalic.otf",
      weight: "200",
      style: "italic",
    },
    {
      path: "../../public/fonts/Neutra2Text-Book.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/Neutra2Text-BookItalic.otf",
      weight: "400",
      style: "italic",
    },
    {
      path: "../../public/fonts/Neutra2Text-Demi.otf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/fonts/Neutra2Text-DemiItalic.otf",
      weight: "500",
      style: "italic",
    },
    {
      path: "../../public/fonts/Neutra2Text-Bold.otf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/Neutra2Text-BoldItalic.otf",
      weight: "700",
      style: "italic",
    },
  ],
  fallback: [ 
    "Geneva", 
    "Verdana",
    "sans-serif"
  ]
});

const recklessText = localFont({
  src: [
    {
      path: "../../public/fonts/Reckless-Light.woff2",
      weight: "200",
      style: "normal",
    },
    {
      path: "../../public/fonts/Reckless-Light.woff",
      weight: "200",
      style: "normal",
    },
    {
      path: "../../public/fonts/Reckless-LightItalic.woff2",
      weight: "200",
      style: "italic",
    },
    {
      path: "../../public/fonts/Reckless-LightItalic.woff",
      weight: "200",
      style: "italic",
    },
    {
      path: "../../public/fonts/Reckless-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/Reckless-Regular.woff",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/Reckless-RegularItalic.woff2",
      weight: "400",
      style: "italic",
    },
    {
      path: "../../public/fonts/Reckless-RegularItalic.woff",
      weight: "400",
      style: "italic",
    },
    {
      path: "../../public/fonts/Reckless-Medium.woff2",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/fonts/Reckless-Medium.woff",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/fonts/Reckless-MediumItalic.woff2",
      weight: "500",
      style: "italic",
    },
    {
      path: "../../public/fonts/Reckless-MediumItalic.woff",
      weight: "500",
      style: "italic",
    },
    {
      path: "../../public/fonts/Reckless-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/Reckless-Bold.woff",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/Reckless-BoldItalic.woff2",
      weight: "700",
      style: "italic",
    },
    {
      path: "../../public/fonts/Reckless-BoldItalic.woff",
      weight: "700",
      style: "italic",
    },
  ],
  fallback: [ 
    "Geneva", 
    "Verdana",
    "Arial",
    "Helvetica",
    "sans-serif"
  ]
});

const tiemposHeadline = localFont({
  src: [
    {
      path: "../../public/fonts/TiemposHeadline-Regular.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/TiemposHeadline-RegularItalic.otf",
      weight: "400",
      style: "italic",
    },
  ],
  fallback: [ 
    "Times New Roman", 
    "Times", 
    "serif"
  ]
});

const pilatHeadline = localFont({
  src: [
    {
      path: "../../public/fonts/Pilat-Heavy.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/Pilat-Heavy.woff",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/Pilat-Heavy.woff2",
      weight: "400",
      style: "italic",
    },
    {
      path: "../../public/fonts/Pilat-Heavy.woff",
      weight: "400",
      style: "italic",
    },
  ],
  fallback: [ 
    "Times New Roman", 
    "Times", 
    "serif"
  ]
});

export const bodyFont = recklessText;
export const headlineFont = pilatHeadline;